//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonForm from "@/components/common/CommonForm";
import LazyFormRow from "@/components/common/LazyFormRow/index";
import componentWithForm from "@/mixins/componentWithForm";

export default {
    name: "SubscribeForm",
    mixins: [componentWithForm],
    components: {LazyFormRow, CommonForm},
    data: function() {
        return {
            formId: 'subscription_form---form-widget',
            formIdParams: {
                source: 'footer'
            }
        }
    },
    props: {
        source: {
            type: String,
            default: 'pop_up'
        }
    },
    methods: {
        successSubmit(result) {
            this.$ga.sendSimpleEvent('popup-subscription')
            let step = result.SubscriptionForm.code === 304 ? 3 : 2;

            this.$emit('changeStep', {
                email: this.email,
                step: step,
                message: result.SubscriptionForm.message
            });
            sessionStorage.setItem('canShowSubscribe', 2);
        }
    },
    computed: {
        email() {
          return (this.keyValueFormData && this.keyValueFormData.email) || "";
        },
        lang() {
            return this.$i18n.locale
        },
        privacyLink() {
            return this.lang === 'ru' ? '/politika-konfidenczialnosti-programmy-loyalnosti-adidas-universe' : '/politika-konfidenczijnosti-programi-loyalnosti-adidas-universe'
        },
    }
}
